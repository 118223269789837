import * as React from 'react';
import * as Styles from '../styles/pages/_company.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import Accent_s from '/static/accent_small.svg';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const CompanyPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  return (
    <Layout location={location}>
      <Seo
        title={t('company.seo.title')}
        description={t('company.seo.description')}
      />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('company.operationCompany')}
      />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <h1>{t('company.operationCompany')}</h1>
            <div className={Styles.company__img}>
              <StaticImage src="../images/paint.png" alt="" />
            </div>
            <div className={Styles.company__contents}>
              <h3>
                <img src={Accent_s} className="accent" alt="accent" />
                {t('company.companyName')}
              </h3>
              <div className={Styles.company__text}>
                <p>{t('company.name')}</p>
              </div>
            </div>
            <div className={Styles.company__contents}>
              <h3>
                <img src={Accent_s} className="accent" alt="accent" />
                {t('company.location')}
              </h3>
              <div className={Styles.company__text}>
                <p>
                  {t('company.address1.office')}
                  <br />
                  {t('company.address1.line1')}
                  <br />
                  {t('company.address1.line2')}
                </p>
              </div>
            </div>
            <div className={Styles.company__contents}>
              <h3>
                <img src={Accent_s} className="accent" alt="accent" />
                {t('company.representative')}
              </h3>
              <div className={Styles.company__text}>
                <p>
                  {t('company.representativeName1')}<br />
                  {t('company.representativeName2')}
                </p>
              </div>
            </div>
            <div className={Styles.company__contents}>
              <h3>
                <img src={Accent_s} className="accent" alt="accent" />
                {t('company.business')}
              </h3>
              <div className={Styles.company__text}>
                <p>
                  {t('company.businessContents1')}<br />
                  {t('company.businessContents2')}
                </p>
              </div>
            </div>
            <div className={Styles.company__contents}>
              <h3>
                <img src={Accent_s} className="accent" alt="accent" />
                {t('company.bank.mainBank')}
              </h3>
              <div className={Styles.company__text}>
                <p>{t('company.bank.bank1')}</p>
              </div>
            </div>
            <div className={Styles.company__contents}>
              <h3>
                <img src={Accent_s} className="accent" alt="accent" />
                {t('company.inquiry')}
              </h3>
              <div className={Styles.company__text}>
                <p>
                  {t('company.form.inquiryForm')}：<Link to={`/contact/`}>{t('company.form.inquiryMessage')}</Link>
                </p>
                <p>
                  {t('company.form.line4')}
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
}

export default CompanyPage;
